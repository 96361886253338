import { combineReducers, AnyAction } from 'redux';
import { REDUX } from 'constants/index';
import { ReportingStateLocationData } from '../store/ReportingState';
import { ReportingLocation } from '../../../types/reporting.type';

const initialState: ReportingStateLocationData = {
    data: [] as ReportingLocation[],
    isLoading: false,
    error: '',
};

const reportingLocations = (state = initialState, action: AnyAction): ReportingStateLocationData => {
    switch (action.type) {
        case `${REDUX.REPORTING_LOCATIONS}/SUCCESS`: {
            return {
                ...state,
                data: action.payload,
            };
        }
        case `${REDUX.REPORTING_LOCATIONS}/LOADING`: {
            return { ...initialState, isLoading: true };
        }
        case `${REDUX.REPORTING_LOCATIONS}/ERROR`: {
            return { ...initialState, error: action.payload || 'Error Occurred' };
        }
        default:
            return state;
    }
};

export default combineReducers({
    reportingLocations,
});
