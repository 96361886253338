var Haiku = require("@haiku/core");
module.exports = {
  metadata: {
    uuid: "b2e6ff4a-06c2-4079-a962-5a06fff39c22",
    version: "0.0.1",
    organization: "RaulF",
    project: "LumenLoader2",
    branch: "master",
    folder: "/Users/raulflores/.haiku/projects/RaulF/LumenLoader2",
    username: "raul+1@dialexa.com",
    root: "https://cdn.haiku.ai/33933f29-3822-466e-b15c-4d5324ad3d3e/744f8a2f8087643bf06d26aeb0523c84e9532597/",
    core: "4.5.1",
    title: "Main",
    type: "haiku",
    relpath: "code/main/code.js"
  },
  options: {},
  states: {},
  eventHandlers: {},
  timelines: {
    Default: {
      "haiku:Main-03757d2ca1026e0a": {
        "style.WebkitTapHighlightColor": "rgba(0,0,0,0)",
        "style.position": "relative",
        "style.overflowX": "hidden",
        "style.overflowY": "hidden",
        "sizeAbsolute.x": 550,
        "sizeAbsolute.y": 400,
        "sizeMode.x": 1,
        "sizeMode.y": 1,
        "sizeMode.z": 1
      },
      "haiku:Path-4581545ec8f11f28": {
        "sizeMode.y": 1,
        "style.position": "absolute",
        "style.padding": "0",
        "style.border": "0",
        "sizeAbsolute.x": 122,
        "sizeMode.x": 1,
        "sizeAbsolute.y": 224,
        "style.margin": "0",
        "translation.x": 275,
        "translation.y": 200,
        "style.zIndex": 1,
        "scale.x": 1,
        "scale.y": 1,
        "rotation.z": {
          "0": { value: 0, edited: true },
          "750": { value: 0, edited: true, curve: "easeInOutCirc" },
          "1317": { value: -6.28, edited: true }
        },
        opacity: 1
      },
      "haiku:Page-2-3571602e082301ca": {
        stroke: "none",
        strokeWidth: 1,
        fill: "none",
        fillRule: "evenodd",
        strokeLinecap: "round",
        strokeLinejoin: "round"
      },
      "haiku:Path-d75f3e258b7c7437": {
        stroke: "#F8DA1E",
        strokeWidth: 10,
        points: "50.5 117 5 117 80.5 5 70.5 94 117 94 43.5 219 49.5 140.5",
        strokeDasharray: {
          "0": { value: 600, edited: true },
          "500": { value: 600, edited: true }
        },
        strokeDashoffset: {
          "0": { value: -600, edited: true, curve: "easeInOutQuad" },
          "750": { value: 0, edited: true },
          "1317": { value: 0, edited: true, curve: "easeInOutQuad" },
          "1950": { value: -600, edited: true }
        }
      }
    }
  },
  template: {
    elementName: "div",
    attributes: { "haiku-id": "Main-03757d2ca1026e0a", "haiku-title": "Main" },
    children: [
      {
        elementName: "svg",
        attributes: {
          version: "1.1",
          xmlns: "http://www.w3.org/2000/svg",
          "xmlns:xlink": "http://www.w3.org/1999/xlink",
          "haiku-id": "Path-4581545ec8f11f28",
          "haiku-title": "Path",
          "haiku-source":
            "designs/LumenLoader2.sketch.contents/slices/Path.svg#lock"
        },
        children: [
          {
            elementName: "g",
            attributes: { "haiku-id": "Page-2-3571602e082301ca", id: "Page-2" },
            children: [
              {
                elementName: "polyline",
                attributes: { "haiku-id": "Path-d75f3e258b7c7437", id: "Path" },
                children: []
              }
            ]
          }
        ]
      }
    ]
  }
};
