import { createSelector } from 'reselect';

import ApplicationState from '../store/ApplicationState';
import UWReviewState, { UWReviewData } from '../store/UWReviewState';
import PropertyTableRowModel from 'views/SubmissionDetails/components/UnderwriterReview/components/Property/PropertyTableRowModel';

const uwReviewState = (state: ApplicationState) => state.uwReview;
const data = (state: UWReviewState) => state.data;
const loading = (state: UWReviewState) => state.isLoading;
const error = (state: UWReviewState) => state.error;
const propertyTableValues = (d: UWReviewData) => (d.propertyTableValues ? d.propertyTableValues : []);
const uwReview = (d: UWReviewData) => {
    return d.underwriterReview;
};
const subjectivities = (d: UWReviewData) => d.subjectivities || [];

export const getUwData = createSelector(uwReviewState, data);

export const getUwReviewLoading = createSelector(uwReviewState, loading);

export const getUwReviewError = createSelector(uwReviewState, error);

export const getUwPropertyTableValues = createSelector(getUwData, propertyTableValues);

export const getUwReview = createSelector(getUwData, uwReview);

export const getUwReviewSubjectivities = createSelector(getUwData, subjectivities);
