import { createTheme } from '@material-ui/core/styles';
import theme from './theme.json';
import overrides from './overrides';
import typography from './typography';

// @ts-ignore
export default createTheme({
    ...theme,
    overrides,
    typography,
});
