import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { LoginCallback } from '@okta/okta-react';

import { ROUTES } from 'constants/index';
import Root from 'views/Root';
import Login from 'views/Login/container';
import Logout from 'views/Logout';
import NotFound from 'views/404';
import NoOu from 'views/NoOu';
import Forbidden from 'views/Forbidden';

import AuthedRoutes from './components/AuthRoutes';

const Routes: React.FunctionComponent = () => (
    <Switch>
        <Route path={ROUTES.callback()} component={LoginCallback} />
        <Route exact path={ROUTES.login()} component={Login} />
        <Route exact path={ROUTES.logout()} component={Logout} />
        <Route exact path={ROUTES.root()} component={Root} />
        <Route
            path={`/(agentdashboard|lumendashboard|submissions|submission|users|organizations|templatemanagement|reporting|auth|home)`}
            component={AuthedRoutes}
        />
        <Route exact path="/404" component={NotFound} />
        <Route exact path="/no-ou" component={NoOu} />
        <Route exact path="/forbidden" component={Forbidden} />
        <Redirect from="*" to="/404" />
    </Switch>
);

export default Routes;
