import * as React from 'react';
import { CircularProgress, Grid } from '@material-ui/core';

interface Props {
    fillHeight?: string;
    size?: number;
}

const defaultProps: Props = {
    fillHeight: '80vh',
    size: 75,
};

const CircularLoader: React.FunctionComponent<Props> = (props) => {
    const { fillHeight, size } = props;
    return (
        <Grid container alignItems="center" justifyContent="center" style={{ height: fillHeight }}>
            <CircularProgress size={size} />
        </Grid>
    );
};

CircularLoader.defaultProps = defaultProps;
export default CircularLoader;
