import React, { CSSProperties } from 'react';

export const sentenceToTitleCase = (input: string = '') => input.split(' ').map(wordToTitleCase).join(' ');

export const wordToTitleCase = (word: string = '') => word.charAt(0).toUpperCase() + word.slice(1);

export const formatDollar = (
    amount: number | string,
    decimalPlaces: number = 2,
    truncateEmptyDecimal: boolean = false
) => {
    const minDecimalPlace =
        truncateEmptyDecimal && (Number(amount) * Math.pow(10, decimalPlaces)) % Math.pow(10, decimalPlaces) === 0
            ? 0
            : decimalPlaces;
    return Number(amount).toLocaleString(undefined, {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: minDecimalPlace,
        maximumFractionDigits: decimalPlaces,
    });
};

export const formatDecimal = (amount: number | string, decimalDigits: number = 3) => {
    return Number(amount).toLocaleString(undefined, {
        style: 'decimal',
        maximumFractionDigits: decimalDigits,
    });
};

export const formatPercent = (amount: number | string, decimalDigits: number = 0) => {
    if (!amount) {
        amount = 0;
    }
    return Number(amount).toLocaleString(undefined, {
        style: 'percent',
        maximumFractionDigits: decimalDigits,
    });
};

export const formatBoolToYesNo = (val: boolean) => (val ? 'YES' : 'NO');

export function gridValueFormatter({ value }: { value: any }) {
    return value || '--';
}

export const currencyToNumber = (value: string) => {
    if (value === '-$') return '0';
    return String(value).replace(new RegExp(',', 'g'), '').replace('-$0', '0').replace('$', '');
};

export const formatMultiLineString = (value?: string | null) => {
    if (!value) {
        return '';
    }
    return value.split('\n').map((sp, idx) => (
        <span key={sp + idx}>
            {sp}
            <br />
        </span>
    ));
};

const modStyle: CSSProperties = {
    color: 'red',
    textDecoration: 'line-through',
};

export const formatModString = (value?: string | null, modValue?: string | null, sideBySide?: boolean) => {
    return (
        <>
            {value !== modValue && (
                <span style={modStyle}>
                    {value}
                    {!sideBySide && <br />}
                    {sideBySide && ' '}
                </span>
            )}
            <span>{modValue}</span>
        </>
    );
};
