import React from 'react';
import { Grid, withStyles, Card, Typography, CardContent } from '@material-ui/core';
import styles from './styles';
import { ReactComponent as Logo } from 'assets/lumen_logo.svg';
import { Spacing } from 'components/Layout/index';
import Logout from 'views/Logout';

interface LoadingProps {
    classes: any;
}

const Forbidden: React.FunctionComponent<LoadingProps> = ({ classes }) => {
    return (
        <>
            <Grid className={classes.root} container alignContent="center" alignItems="center" justifyContent="center">
                <Grid item className={classes.content}>
                    <Card className={classes.card}>
                        <div className={classes.cardHeader}>
                            <Logo />
                        </div>
                        <CardContent className={classes.content}>
                            <Typography variant="h5">Uh-oh...your user is not authorized to use Kurrent</Typography>
                            <Spacing height="32px" />
                            <Typography variant="body2">Please contact Lumen support to let us know.</Typography>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
            <Logout delayMs={3000} />
        </>
    );
};

export default withStyles(styles)(Forbidden);
