export const STATUS_TYPES = {
    NEW: 'NEW',
    AGENT_REVIEW: 'AGENT_REVIEW',
    BOUND: 'BOUND',
    INCOMPLETE_SUBMISSION: 'INCOMPLETE_SUBMISSION',
    READY_FOR_CLEARING: 'READY_FOR_CLEARING',
    BLOCKED_BY_CLEARING: 'BLOCKED_BY_CLEARING',
    READY_FOR_UNDERWRITING: 'READY_FOR_UNDERWRITING',
    UNDERWRITER_DECLINED: 'UNDERWRITER_DECLINED',
    READY_TO_QUOTE: 'READY_TO_QUOTE',
    QUOTED: 'QUOTED',
    REQUOTE_REQUESTED: 'REQUOTE_REQUESTED',
    BIND_REQUEST_REVIEW: 'BIND_REQUEST_REVIEW',
    ISSUED: 'ISSUED',
    ENDORSEMENT_REQUEST_REVIEW: 'ENDORSEMENT_REQUEST_REVIEW',
    EXPIRED: 'EXPIRED',
    READY_TO_ISSUE: 'READY_TO_ISSUE',
    CANCELLED: 'CANCELLED',
    ALL: 'ALL',
    RETURNED_TO_UW: 'RETURNED_TO_UW',
    WITHDRAWN: 'WITHDRAWN',
    INVALIDATED: 'INVALIDATED',
};

export const ACTION_TYPES = {
    SUBMISSION_DETAILS: 'SUBMISSION_DETAILS',
    SUBMISSION_LOCATIONS: 'SUBMISSION_LOCATIONS',
    SUBMISSION_LOSSES: 'SUBMISSION_LOSSES',
    SUBMISSION_DOCS_NARRATIVE: 'SUBMISSION_DOCS_NARRATIVE',
    ENDORSEMENT_DOCS_NARRATIVE: 'ENDORSEMENT_DOCS_NARRATIVE',
    LUMEN_QUOTE: 'LUMEN_QUOTE',
    AGENT_QUOTE: 'AGENT_QUOTE',
    ISSUANCE: 'ISSUANCE',
    ISSUANCE_MONIED: 'ISSUANCE_MONIED',
    ISSUANCE_NONMONIED: 'ISSUANCE_NONMONIED',
    ORGANIZATIONAL_UNIT_DETAILS: 'ORGANIZATIONAL_UNIT_DETAILS',
    SUBMISSION_ADD_DOCS: 'SUBMISSION_ADD_DOCS',
};

export const SUBMISSION_TYPES = {
    SUBMISSION: 'SUBMISSION',
    ENDORSEMENT_ADD: 'ENDORSEMENT_AL',
    ENDORSEMENT_REMOVE: 'ENDORSEMENT_RL',
    ENDORSEMENT_INFO: 'ENDORSEMENT_IN',
    ENDORSEMENT_ADJUSTMENT: 'ENDORSEMENT_AJ',
    ENDORSEMENT_CANCELLATION: 'ENDORSEMENT_CA',
};

export const ENDORSEMENT_TYPES = {
    MONIED_LOCATION_ADDITION: 'MONIED_LOCATION_ADDITION',
    MONIED_LOCATION_REMOVAL: 'MONIED_LOCATION_REMOVAL',
    NON_MONIED: 'NON_MONIED',
    MONIED_ADJUSTMENT: 'MONIED_ADJUSTMENT',
    CANCELLATION: 'CANCELLATION',
};

export const SUBMISSION_UW_STATUS_TYPES = {
    WAITING_AGENT: 'WAITING_AGENT',
    UW_REVIEW: 'UW_REVIEW',
    REFERRED_CARRIER: 'REFERRED_CARRIER',
    REFERRED_USER: 'REFERRED_USER',
    UW_COMPLETE: 'UW_COMPLETE',
};
