import { createSelector } from 'reselect';

import ApplicationState from '../store/ApplicationState';
import SubmissionSOVReport from 'application/Models/SubmissionSOVReport';
import { getGridSelectors } from '../lib/gridSelectorCreators';
import { getSelectors } from '../lib/selectorCreator';

const sovState = (state: ApplicationState) => state.sov;
const documents = (report: SubmissionSOVReport) => (report ? report.documents || [] : []);
const locations = (report: SubmissionSOVReport) => (report ? report.locations || [] : []);
const endorsedLocations = (report: SubmissionSOVReport) => (report ? report.endorsedLocations || [] : []);

const selectors = getSelectors(sovState);
const gridSelectors = getGridSelectors(sovState);

export const getSovGridData = gridSelectors.getGridData;
export const getSovGridError = gridSelectors.getGridError;

export const getSovReport = selectors.getData;
export const getSovLoading = selectors.getLoading;
export const getSovError = selectors.getLoading;
export const getSovHasResolved = selectors.getHasResolved;

export const getSovDocuments = createSelector(getSovReport as any, documents);

export const getSovLocations = createSelector(getSovReport as any, locations);
export const getSovEndorsedLocations = createSelector(getSovReport as any, endorsedLocations);
