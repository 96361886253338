import { COLORS } from 'constants/index';

const MuiButton = {
    root: {
        fontWeight: 700,
        fontSize: '13px',
        lineHeight: '1.14',
        letterSpacing: '1.3px',
        minWidth: '120px',
        height: '40px',
        color: COLORS.SECONDARY,
        borderRadius: '6px',
        boxShadow: 'none !important',
        marginRight: '20px',
        marginBottom: '5px',
        '&:last-child': {
            marginRight: '0',
        },
        '&$disabled': {
            color: COLORS.GRAY_MED,
            backgroundColor: COLORS.GRAY_LIGHT,
        },
    },
    containedPrimary: {
        color: COLORS.SECONDARY,
        backgroundColor: COLORS.PRIMARY,
        '&:hover': {
            backgroundColor: COLORS.PRIMARY_LIGHT,
        },
    },
    outlinedPrimary: {
        color: COLORS.SECONDARY,
        borderColor: COLORS.PRIMARY,
        '&:hover': {
            borderColor: COLORS.PRIMARY,
            backgroundColor: COLORS.PRIMARY_LIGHTER,
        },
    },
    textPrimary: {
        color: COLORS.SECONDARY,
        textDecoration: 'underline',
        fontSize: '13px',
        lineHeight: '18px',
        '&:hover': {
            textDecoration: 'underline',
        },
    },
    sizeSmall: {
        maxWidth: '48px',
        minWidth: '48px',
        height: '48px',
        borderRadius: '16px',
        padding: '0',
        margin: '0',
    },
    // ,mini: {
    //     height: '24px',
    //     width: '24px',
    //     borderRadius: '8px'
    // }
};

export default MuiButton;
