import { StyleRules } from '@material-ui/core/styles';

const styles = (): StyleRules => ({
    buttonProgress: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12,
        fill: '#000',
    },
});

export default styles;
