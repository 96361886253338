import React from 'react';
import { Switch, Redirect, Route } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useActions } from 'hooks/useActions';

import {
    getTopLevelUserGroup,
    getIsAuthenticated,
    getUserGroups,
    getTypesLoading,
    getTypesResolved,
    getActiveUser,
    getActiveUserIsLoading,
    getActiveUserProducer,
    getToken,
    getIsSignInError,
} from 'application/redux/selectors';
import { AuthenticatedUser } from 'application/Models/User';
import * as Actions from 'application/redux/actions';
import { OKTA_GROUPS, ROUTES } from 'constants/index';
import { getCurrentUser } from 'services/users';

import Loading from 'views/Loading';

import PermissionRoute from './PermissionRoute';
import { useHistory } from 'react-router-dom';
import HomeRoute from 'routes/components/HomeRoute';
import { User } from '../../types/users.types';

import { createActiveUserProducer } from 'helpers/ActiveUserProducer/ActiveUserProducerFactory';
import { getAssignedProducer } from 'application/redux/selectors/submissionUserPreferences';
import { ActiveUserProducer } from 'types/types.type';

import { getIsAgentRole } from 'helpers/roles';
import { useOktaAuth } from '@okta/okta-react';
import {
    getLocationOptionsLoading,
    getLocationOptionsResolved,
} from '../../application/redux/selectors/locationOptions';
import { UserClaims } from '@okta/okta-auth-js/lib/types';

const OrganizationForm = React.lazy(() => import('views/OrganizationForm'));
const Organizations = React.lazy(() => import('views/Organizations'));
const Reporting = React.lazy(() => import('views/Reporting'));
const SubmissionDetails = React.lazy(() => import('views/SubmissionDetails'));
const SubmissionForm = React.lazy(() => import('views/SubmissionForm'));
const Submissions = React.lazy(() => import('views/Submissions'));
const TemplateManagement = React.lazy(() => import('views/TemplateManagement'));
const UserForm = React.lazy(() => import('views/UserForm'));
const Users = React.lazy(() => import('views/Users'));
const AgentDashboard = React.lazy(() => import('views/AgentDashboard'));
const LumenDashboard = React.lazy(() => import('views/LumenDashboard'));

const AuthRoutes: React.FunctionComponent = () => {
    // Local State
    const [hasOu, setHasOu] = React.useState(false);
    const [forbidden, setForbidden] = React.useState(false);
    const [ouChecked, setOuChecked] = React.useState(false);

    // Global State
    const userGroups = useSelector(getUserGroups);
    const isAuthenticated = useSelector(getIsAuthenticated);
    const isSignInError = useSelector(getIsSignInError);
    const cachedToken = useSelector(getToken);
    const group = useSelector(getTopLevelUserGroup);
    const isAllowedInNamespace = userGroups.includes(group);
    const isTypesLoading = useSelector(getTypesLoading);
    const isLocationOptionsLoading = useSelector(getLocationOptionsLoading);
    const hasTypesResolved = useSelector(getTypesResolved);
    const hasLocationOptionsResolved = useSelector(getLocationOptionsResolved);
    const activeUser = useSelector(getActiveUser);
    const activeUserProducer = useSelector(getActiveUserProducer);
    const activeUserIsLoading = useSelector(getActiveUserIsLoading);
    const assignedProducer = useSelector(getAssignedProducer);
    const { signIn, clearSignIn, registerUser, getTypes, getLocationOptions, setAssignedProducer } = useActions({
        signIn: Actions.signInSuccess,
        clearSignIn: Actions.logoutSuccess,
        registerUser: Actions.activeUserSuccess,
        getTypes: Actions.fetchAllTypes,
        getLocationOptions: Actions.fetchAllLocationOptions,
        setAssignedProducer: Actions.setAssignedProducer,
    });

    const history = useHistory();
    const { authState, oktaAuth } = useOktaAuth();

    const validateAuthenticated = async () => {
        if (authState !== null) {
            if (authState.isAuthenticated && !isSignInError) {
                const token = authState.accessToken?.accessToken;
                if (isAuthenticated) {
                    if (cachedToken === token) {
                        // we have the right token cached, we are "signed in"
                        return;
                    }
                }

                // either we are "signed in" with the wrong token or not "signed in" at all, do that now
                const user: UserClaims = await oktaAuth.getUser();
                signIn({ token, user });
            } else {
                // we aren't authenticated with Okta
                if (isAuthenticated) {
                    // our authentication info is old, lets clear that now
                    clearSignIn();
                }
                if (authState.isAuthenticated) {
                    // out Otka state claims it is authenticated, but probably the server returned with 401, we need a new token
                    history.push(ROUTES.logout());
                } else {
                    const fromUri = history.createHref(history.location);
                    history.push(ROUTES.login(fromUri));
                    // oktaAuth.signInWithRedirect({originalUri: fromUri});
                }
            }
        }
    };

    const checkAuth = async () => {
        await validateAuthenticated();
        if (authState !== null && isAuthenticated) {
            await fetchCurrentUser();
            preloadTypes();
            preloadLocationOptions();
        }
    };

    const fetchActiveAgent = async (fetchedActiveUser: User): Promise<ActiveUserProducer> => {
        // TODO modify this to support users assigned to mulitple Organazational Units
        const activeAgent = createActiveUserProducer(fetchedActiveUser, group);
        await activeAgent.fetchAvailableAgents();

        return activeAgent;
    };

    const fetchCurrentUser = async () => {
        try {
            const currentUser = await getCurrentUser();
            if (currentUser) {
                const activeAgent = await fetchActiveAgent(currentUser);
                registerUser({ activeUser: currentUser, activeUserProducer: activeAgent });

                checkOu(currentUser);
            }
        } catch (e) {
            // this user isn't authorized
            if (e.statusCode === 403) {
                setForbidden(true);
            }
        }
    };
    const checkOu = (currentUser: User) => {
        let temp = false;
        if (!getIsAgentRole(group)) {
            temp = true;
        }

        const ouResult = currentUser.organizationalUnits;
        if (ouResult && ouResult.length && ouResult.length > 0) {
            temp = true;
        }
        setHasOu(temp);
        setOuChecked(true);
    };

    const preloadTypes = () => {
        !isTypesLoading && !hasTypesResolved && getTypes();
    };

    const preloadLocationOptions = () => {
        !isLocationOptionsLoading && !hasLocationOptionsResolved && getLocationOptions();
    };

    React.useEffect(() => {
        checkAuth();
    }, [authState, group, isSignInError, isAuthenticated]);

    React.useEffect(() => {
        if (!activeUserIsLoading) {
            // we have to wait until the user is registered before we can verify the activeProducer since that is dependant on the user
            // make sure the assigned producer is still valid
            setAssignedProducer(activeUser, activeUserProducer.validateAssignedProducer(assignedProducer));
        }
    }, [activeUserIsLoading]);
    // React.useEffect(preloadTypes, []);

    if (authState === null) {
        return <Loading />;
    }

    if (forbidden) {
        return <Redirect to={'/forbidden'} />;
    }
    // if (!isAuthenticated) {
    //     return <Redirect to={ROUTES.login()} />;
    // }
    if (!hasTypesResolved || !hasLocationOptionsResolved || !ouChecked) {
        return <Loading />;
    }
    if (!isAllowedInNamespace || group === 'auth') {
        // TODO what is this case and is it necessary?
        return <Redirect to={'/auth'} />;
    }
    if (!hasOu) {
        return <Redirect to={'/no-ou'} />;
    }

    return (
        <Switch>
            <PermissionRoute
                permissions={[OKTA_GROUPS.ADMIN]}
                path={'/organizations/create/:createId'}
                component={OrganizationForm}
            />
            <PermissionRoute
                permissions={[OKTA_GROUPS.ADMIN]}
                path={'/organizations/create'}
                component={OrganizationForm}
            />
            <PermissionRoute
                permissions={[OKTA_GROUPS.ADMIN]}
                path={'/organizations/edit/:editId'}
                component={OrganizationForm}
            />
            <PermissionRoute
                permissions={[OKTA_GROUPS.ADMIN]}
                path={ROUTES.organizations()}
                component={Organizations}
            />
            <PermissionRoute
                permissions={[
                    OKTA_GROUPS.ADMIN,
                    OKTA_GROUPS.ACCOUNT_MANAGER,
                    OKTA_GROUPS.UNDERWRITER,
                    OKTA_GROUPS.AGENT,
                ]} // Everyone thats authed allowed in
                path={'/submissions'}
                component={Submissions}
            />
            <PermissionRoute permissions={[OKTA_GROUPS.AGENT]} path={'/agentdashboard'} component={AgentDashboard} />
            <PermissionRoute
                permissions={[OKTA_GROUPS.ADMIN, OKTA_GROUPS.ACCOUNT_MANAGER, OKTA_GROUPS.UNDERWRITER]}
                path={'/lumendashboard'}
                component={LumenDashboard}
            />
            <PermissionRoute
                permissions={[OKTA_GROUPS.AGENT]}
                path={'/submission/form/:id/:step?'}
                component={SubmissionForm}
            />
            <PermissionRoute
                permissions={[
                    OKTA_GROUPS.ADMIN,
                    OKTA_GROUPS.ACCOUNT_MANAGER,
                    OKTA_GROUPS.UNDERWRITER,
                    OKTA_GROUPS.AGENT,
                ]}
                path={'/submission/:id/:page?'}
                component={SubmissionDetails}
            />
            <PermissionRoute
                permissions={[OKTA_GROUPS.ADMIN]}
                path={'/templatemanagement'}
                component={TemplateManagement}
            />
            <PermissionRoute permissions={[OKTA_GROUPS.ADMIN]} path={ROUTES.usersEdit()} component={UserForm} />
            <PermissionRoute permissions={[OKTA_GROUPS.ADMIN]} path={ROUTES.usersCreate()} component={UserForm} />
            <PermissionRoute permissions={[OKTA_GROUPS.ADMIN]} path={ROUTES.users()} component={Users} />
            <PermissionRoute
                permissions={[OKTA_GROUPS.ADMIN, OKTA_GROUPS.CARRIER_REP]}
                path={ROUTES.reporting()}
                component={Reporting}
            />
            <Route path="/home" component={HomeRoute} />
            <Redirect from="/auth" to="/home" />
            <Redirect from="*" to="/404" />
        </Switch>
    );
};

export default AuthRoutes;
