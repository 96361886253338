import { getHeaders, SubmissionURL } from './config';
import { checkStatus } from './normalizer';
import { setTypes } from 'helpers/typeHelper';
import moment, { Moment } from 'moment';
import {
    AgentSummaryTableResponse,
    FinancialControlTotalResponse,
    LocationAccumulationSummary,
    LocationInspectionDataResponse,
} from '../types/reporting.type';

export async function fetchReportingLocations() {
    const url = `${SubmissionURL}/reportingLocations`;
    const options = {
        method: 'GET',
        headers: getHeaders(),
    };
    return fetch(url, options)
        .then(checkStatus)
        .then((data) => data.json())
        .then(setTypes);
}

export async function fetchReportingAccumulationLocations() {
    const url = `${SubmissionURL}/reportingAccumulationLocations`;
    const options = {
        method: 'GET',
        headers: getHeaders(),
    };
    return fetch(url, options)
        .then(checkStatus)
        .then((data) => data.json());
}

export interface FetchBordereauOptions {
    startDate?: Moment;
    endDate?: Moment;
    reportEntity?: string;
    contentType?: string;
}

export interface FetchControlTotalsOptions {
    startDate?: Moment;
    endDate?: Moment;
}

export interface FetchInspectionLocationDataOptions {
    startDate?: Moment;
    endDate?: Moment;
}

function parameterizeFetchOptions(fetchOptions: object) {
    return Object.entries(fetchOptions).map(([key, val]) => {
        if (moment.isMoment(val)) {
            return `${key}=${val.format('YYYY-MM-DD')}`;
        } else {
            return `${key}=${val}`;
        }
    });
}

export async function fetchMonthlyBordereau(fetchOptions: FetchBordereauOptions) {
    const contentType = fetchOptions.contentType;
    delete fetchOptions.contentType;
    const paramStr = parameterizeFetchOptions(fetchOptions).join('&');
    const url = `${SubmissionURL}/reportingBordereau?` + paramStr;
    const options = {
        method: 'GET',
        headers: getHeaders(),
    };
    if (contentType) {
        options.headers.set('Accept', contentType);
    }
    return fetch(url, options).then(checkStatus);
}

export async function fetchFinancialControlTotals(
    fetchOptions: FetchControlTotalsOptions
): Promise<FinancialControlTotalResponse> {
    const paramStr = parameterizeFetchOptions(fetchOptions).join('&');
    const url = `${SubmissionURL}/reportingFinancialControlTotals?` + paramStr;
    const options = {
        method: 'GET',
        headers: getHeaders(),
    };
    return fetch(url, options)
        .then(checkStatus)
        .then((data) => data.json());
}

export async function fetchInspectionLocationData(
    fetchOptions: FetchInspectionLocationDataOptions
): Promise<LocationInspectionDataResponse[]> {
    const paramStr = parameterizeFetchOptions(fetchOptions).join('&');
    const url = `${SubmissionURL}/reportingLocationInspectionData?` + paramStr;
    const options = {
        method: 'GET',
        headers: getHeaders(),
    };
    return fetch(url, options)
        .then(checkStatus)
        .then((data) => data.json());
}

export async function fetchLocationAccumulationsSummary(
    submissionId: string,
    locationIds: number[]
): Promise<LocationAccumulationSummary[]> {
    const url = `${SubmissionURL}/submission/${submissionId}/accumulationSummary?locationIds=${locationIds.join(',')}`;
    const options = {
        method: 'GET',
        headers: getHeaders(),
    };
    return fetch(url, options)
        .then(checkStatus)
        .then((data) => data.json());
}

export async function fetchAgentSummaryTable(): Promise<AgentSummaryTableResponse[]> {
    const url = `${SubmissionURL}/reporting/agentSummaryTable`;
    const options = {
        method: 'GET',
        headers: getHeaders(),
    };
    return fetch(url, options)
        .then(checkStatus)
        .then((data) => data.json());
}
