import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { ThemeProvider as MuiThemeProvider } from '@material-ui/core/styles';

import { store, persistor } from 'application/redux/store';
import theme from 'ui/theme';

import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-material.css';
import 'ag-grid-community/dist/styles/ag-theme-balham.css';
import 'ag-grid-enterprise';

import './index.css';
import { PersistGate } from 'redux-persist/integration/react';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';

// pick a date util library
import MomentUtils from '@date-io/moment';

import { LicenseManager } from 'ag-grid-enterprise';
import KurrentApp from 'KurrentApp';
import LrAuthentication from 'application/Providers/Authentication/index';

LicenseManager.setLicenseKey(
    'CompanyName=Lumen Risk Services, LLC,LicensedApplication=Kurrent,LicenseType=SingleApplication,LicensedConcurrentDeveloperCount=1,LicensedProductionInstancesCount=1,AssetReference=AG-019235,ExpiryDate=28_October_2022_[v2]_MTY2NjkxMTYwMDAwMA==02f0207488719bcf9e83e3a6d24c8ef7'
);

ReactDOM.render(
    <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
            <BrowserRouter>
                <LrAuthentication>
                    <MuiPickersUtilsProvider utils={MomentUtils}>
                        <MuiThemeProvider theme={theme}>
                            <div>
                                <KurrentApp />
                            </div>
                        </MuiThemeProvider>
                    </MuiPickersUtilsProvider>
                </LrAuthentication>
            </BrowserRouter>
        </PersistGate>
    </Provider>,
    document.getElementById('root') as HTMLElement
);
