import { createSelector } from 'reselect';

import ApplicationState from '../store/ApplicationState';
import SubmissionDetailsState from '../store/SubmissionDetailsState';
import { SubmissionDetails } from 'types/submission.type';
import { UnderwriterReview } from 'types/underwriting.type';
import { getAssignedProducer } from './submissionUserPreferences';
import { Organization } from 'types/organization.type';

const submissionDetailsState = (state: ApplicationState) => state.submissionDetails;
const submissionProducer = (state: SubmissionDetails) => state.producer;
const data = (state: SubmissionDetailsState) => state.data;
const error = (state: SubmissionDetailsState) => state.error;
const isLoading = (state: SubmissionDetailsState) => state.isLoading;
const locations = (state: SubmissionDetails) => state.locations;
const losses = (state: SubmissionDetails) => state.losses;
const documents = (state: SubmissionDetails) => state.documents;
const underwriterReviews = (state: SubmissionDetails) => state.underwriterReviews || ([] as UnderwriterReview[]);
const underwritingNotes = (state: SubmissionDetails) => state.underwritingNotes;
const narrative = (state: SubmissionDetails) => state.narrative;
const actionTypeCode = (state: SubmissionDetails) => (state.actionType ? state.actionType.code : '');
const hasResolved = (state: SubmissionDetailsState) => state.hasResolved;
const getUwReviewById = (id: string, reviews: UnderwriterReview[]) =>
    id === '' ? reviews[0] : reviews.find((r) => r.id === id);

// the effective producer represents a context aware usable producer for components
const effectiveProducer = (currentSubmissionProducer: Organization, assignedProducer: Organization) => {
    if (currentSubmissionProducer && currentSubmissionProducer.id) {
        // if we are in the context of an active submission, we use that
        return currentSubmissionProducer;
    } else return assignedProducer;
};

export const getSubmissionDetails = createSelector(submissionDetailsState, data);

export const getSubmissionProducer = createSelector(getSubmissionDetails, submissionProducer);

export const getEffectiveProducer = createSelector(getSubmissionProducer, getAssignedProducer, effectiveProducer);

export const getDetailsLocations = createSelector(getSubmissionDetails, locations);

export const getDetailsLosses = createSelector(getSubmissionDetails, losses);

export const getDetailsReviews = createSelector(getSubmissionDetails, underwriterReviews);

export const getDetailsReviewById = (id: string) =>
    createSelector(
        // @ts-ignore
        getDetailsReviews,
        (reviews: UnderwriterReview[]) => getUwReviewById(id, reviews)
    );

export const getDetailsDocuments = createSelector(getSubmissionDetails, documents);

export const getDetailsNarrative = createSelector(getSubmissionDetails, narrative);

export const getDetailsUWNotes = createSelector(getSubmissionDetails, underwritingNotes);

export const getSubmissionDetailsStep = createSelector(getSubmissionDetails, actionTypeCode);

export const getSubmissionDetailsLoading = createSelector(submissionDetailsState, isLoading);

export const getSubmissionDetailsHasResolved = createSelector(submissionDetailsState, hasResolved);

export const getSubmissionDetailsError = createSelector(submissionDetailsState, error);
