export const DIALOG_NAMES = {
    ADJUSTMENT: 'ADJUSTMENT',
    EDIT: 'EDIT',
    NONE: 'NONE',
    REMOVAL: 'REMOVAL',
    CANCEL: 'CANCEL',
    EXPIRE: 'EXPIRE',
    UNEXPIRE: 'UNEXPIRE',
    RENEW: 'RENEW',
};
