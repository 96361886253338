import { COLORS } from 'constants/index';

const MuiButtonGroup = {
    groupedOutlinedPrimary: {
        color: COLORS.SECONDARY,
        borderColor: COLORS.PRIMARY,
        '&:hover': {
            borderColor: COLORS.PRIMARY,
            backgroundColor: COLORS.PRIMARY_LIGHTER,
        },
    },
    '& button': {
        sizeSmall: {
            maxWidth: '40px',
            minWidth: '40px',
            height: '40px',
            borderRadius: '16px',
            padding: '0',
            margin: '0',
        },
    },
};

export default MuiButtonGroup;
