import * as React from 'react';
import { Grid } from '@material-ui/core';
import Spacing from './Spacing';

interface Props {
    actions?: React.ReactNode;
    children?: React.ReactNode;
    leftPage?: React.ReactNode;
    rightPage?: React.ReactNode;
}

const Book: React.FC<Props> = (props) => {
    const { actions, children, leftPage, rightPage } = props;
    const leftPageSize = rightPage ? 6 : 12;
    return (
        <Grid container spacing={2} style={{ margin: 0, width: '100%' }}>
            <Grid item md={leftPageSize} xs={12}>
                {leftPage && leftPage}
                {children && children}
                {actions && (
                    <>
                        <Spacing height="32px" />
                        {actions}
                    </>
                )}
            </Grid>
            {rightPage && (
                <Grid item md={6} xs={12}>
                    {rightPage}
                </Grid>
            )}
        </Grid>
    );
};

export default Book;
