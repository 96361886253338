import { COLORS } from 'constants/index';

const MuiOutlinedInput = {
    root: {
        '&$focused $notchedOutline': {
            borderColor: `${COLORS.GRAY_MED}`,
        },
        '&:hover $notchedOutline': {
            borderColor: `${COLORS.GRAY_MED} !important`,
        },
        '&$error $notchedOutline': {
            borderColor: `${COLORS.ERROR} !important`,
        },
    },
    notchedOutline: {
        borderColor: `${COLORS.GRAY_LIGHT}`,
    },
};

export default MuiOutlinedInput;
