import { combineReducers, AnyAction } from 'redux';
import { REDUX } from 'constants/index';
import { DocumentActionType, SubmissionUWStatusType, TypesData } from 'types/types.type';

export const initialState: TypesData = {
    actionTypes: {},
    constructionTypes: {},
    documentTypes: {},
    documentActionTypes: {} as DocumentActionType,
    endorsementTypes: {},
    floodZoneTypes: {},
    locationClassTypes: {},
    logTypes: {},
    ownershipTypes: {},
    perilTypes: {},
    perilTypesList: [],
    propertyTypes: {},
    roofCoveringTypes: {},
    roofTypes: {},
    statusTypes: {},
    wiringTypes: {},
    roleTypes: {},
    organizationTypes: {},
    submissionUnderwritingStatusTypes: {} as SubmissionUWStatusType,
};

const data = (state = initialState, action: AnyAction): TypesData => {
    switch (action.type) {
        case `${REDUX.TYPES}/SUCCESS`: {
            return action.payload || state;
        }
        case `${REDUX.TYPES}/LOADING`:
        case `${REDUX.TYPES}/ERROR`: {
            return initialState;
        }
        default:
            return state;
    }
};

function error(state: string = '', action: AnyAction): string {
    switch (action.type) {
        case `${REDUX.TYPES}/ERROR`: {
            return action.payload || '';
        }
        case `${REDUX.TYPES}/LOADING`: {
            return '';
        }
        default:
            return state;
    }
}

function isLoading(state: boolean = false, action: AnyAction): boolean {
    switch (action.type) {
        case `${REDUX.TYPES}/LOADING`: {
            return true;
        }
        case `${REDUX.TYPES}/SUCCESS`:
        case `${REDUX.TYPES}/ERROR`: {
            return false;
        }
        default:
            return state;
    }
}

function hasResolved(state: boolean = false, action: AnyAction): boolean {
    switch (action.type) {
        case `${REDUX.TYPES}/ERROR`:
        case `${REDUX.TYPES}/LOADING`: {
            return false;
        }
        case `${REDUX.TYPES}/SUCCESS`: {
            return true;
        }
        default:
            return state;
    }
}

export default combineReducers({
    data,
    error,
    isLoading,
    hasResolved,
});
