export const ERRORS = {
    API_ERROR_MSG_FULL: 'Cannot Fetch Data; please contact customer support',
    API_ERROR_MSG_TITLE: 'Cannot Fetch Data',
    API_ERROR_MSG_BODY: 'Please contact customer support',
    FORBIDDEN_API_ERROR_MSG_TITLE: 'Unauthorized',
    FORBIDDEN_API_ERROR_MSG_BODY: 'The current user is not authorized for this page',
};

export const OKTA_GROUPS = {
    AGENT: 'SubmissionAgents',
    ACCOUNT_MANAGER: 'AccountManagers',
    UNDERWRITER: 'Underwriters',
    ADMIN: 'Admins',
    CARRIER_REP: 'CarrierReps',
};

export const YES_NO_OPTIONS = {
    YES: 'Yes',
    NO: 'No',
};

export * from './copy';
export * from './dialogs';
export * from './documents';
export * from './locations';
export * from './grid';
export * from './redux';
export { default as ROUTES } from './routes';
export * from './states';
export * from './styles';
export * from './types';
