export const QUOTE_REVIEW_COPY =
    'If you would like to request binding, please attach the signed loss certification and signed TRIA form from the insured and click below. Account is not considered bound until request has been reviewed and approved by Lumen. You may check the status of your bind request in your submission list.\n\n You may also request a requote if you would like to request a revision to quoted terms and conditions.';

export const POLICY_REVIEW_COPY =
    'Coverage is bound! Please review your binder, policy and invoice. Payment is due 20 days from the date of binding. Thank you for your business!';

export const SUBMISSION_COMPLETE_COPY =
    'Your submission has been sent for clearance. If Lumen has not received a prior submission, the account will be reserved for you and sent to our underwriting team for review.';

export const SKIP_LOSSES_COPY =
    "You haven't entered any losses. Would you like to continue anyway? Please indicate why losses are unable to be entered at this point. Verifiable loss history will be required for Lumen to quote.";

export const SKIP_LOSSES_NARRATIVE_PLACEHOLDER =
    'ex. new construction, no losses or still waiting on loss runs from insured';

export const REQUOTE_REQUEST_COMPLETE_COPY =
    "Your requote request has been sent. We'll review the request and get back to you!";

export const SOV_VALIDATION_COPY = 'Please complete the highlighted fields before submitting.';
export const SOV_DELETE_ERROR_COPY = 'You cannot delete all of the locations listed on a submission.';

export const SUBMISSION_AGENT_SELECT_NO_AGENTS =
    'You are not set-up as a Licensed Agent in our system, nor have you been assigned to any. Please contact Lumen for further information.';

export const PRODUCER_CONTEXT_SELECT_DIALOG_COPY = 'Please select a Producer or Agency to view.';

export const SUBMISSION_AGENT_NO_SUBMISSIONS =
    'You have not created any submissions. Please use the "Create New Submission" button to get started!';

export const SUBMISSION_AGENCY_NO_SUBMISSIONS =
    'No submissions have been created for this agency. Please select a licensed agent above to get started!';

export const SUBMISSION_STATUS_NO_SUBMISSIONS = (status: string) =>
    `There are no submissions that are in the ${status} state`;

export const SUBMISSION_UNREAD_MESSAGES_NO_SUBMISSIONS = 'There are no submissions with unread messages';

export const FINANCIAL_NET_DUE_HOVER_COPY = 'This value is net the agent commission';

export const UPLOAD_LOSS_RUN_YEAR_HOVER_COPY =
    'Please Select the Year Corresponding to the Final Year of the Run Document';

export const SUBMISSION_FORM_DOCUMENT_INSTRUCTIONS_COPY =
    'Your submission must include an Acord application and a minimum of the last three years of loss runs, although five years is preferred.  If any loss history is missing, please notate for your underwriter in the Submission Narrative.  Please feel free to include any other pertinent submission documents (supplementals, photos, etc).';

export const ADD_ENDORSEMENT_FORM_DOCUMENT_INSTRUCTIONS_COPY =
    'Your endorsement request must include a minimum of the last three years of loss runs, although five years is preferred. If any loss history is missing, please notate for your underwriter in the Submission Narrative. Please feel free to include any other pertinent documents (supplementals, photos, etc).';

export const LICENSE_EXPIRED_AGENT_CREATE_SUBMISSION_COPY =
    'Our records indicate your license has expired. Please contact Lumen to provide an update before creating a submission.';

export const LICENSE_EXPIRED_SUPPORT_CREATE_SUBMISSION_COPY =
    'Our records indicate the license for this producer has expired. Please contact Lumen to provide an update before creating a submission.';
