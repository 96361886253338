import * as React from 'react';
import { withStyles, Button, CircularProgress } from '@material-ui/core';
import { ButtonProps } from '@material-ui/core/Button';
import styles from '../styles/Button';

type ButtonType = 'primary' | 'secondary' | 'text';

interface Props extends ButtonProps {
    classes?: any;
    isEnabled?: boolean;
    isLoading?: boolean;
    isVisible?: boolean;
    lrType?: ButtonType;
    onClick?: (e?: any) => void;
}

const defaultProps: Props = {
    isEnabled: true,
    isVisible: true,
};

function getVariantByType(type: ButtonType) {
    switch (type) {
        case 'primary':
            return 'contained';
        case 'secondary':
            return 'outlined';
        case 'text':
            return 'text';
        default:
            return '';
    }
}

class LrButton extends React.Component<Props> {
    static defaultProps = defaultProps;
    render() {
        const {
            type,
            isEnabled,
            isLoading,
            isVisible,
            children,
            classes,
            onClick,
            variant,
            lrType,
            color,
            ...rest
        } = this.props;

        const customVriant = lrType ? getVariantByType(lrType) : variant;
        const customColor = lrType ? 'primary' : color;

        if (!isVisible) return <></>;

        return (
            <Button
                variant={customVriant || 'contained'}
                disabled={isLoading || !isEnabled}
                onClick={onClick}
                color={customColor || 'default'}
                type={type}
                {...rest}
            >
                {isLoading && <CircularProgress size={24} color="secondary" className={classes.buttonProgress} />}
                {!isLoading && <>{children}</>}
            </Button>
        );
    }
}

export default withStyles(styles)(LrButton);
