import { STATUS_CODES } from 'http';

class HTTPError extends Error {
    public statusCode: number;
    constructor(code: number, message: string, extras?: any) {
        super(message || STATUS_CODES[code]);
        if (arguments.length >= 3 && extras) {
            Object.assign(this, extras);
        }
        this.name = this.toName(code);
        this.statusCode = code;
    }

    toName(code: number) {
        const suffix = Math.floor(code / 100) === 4 || Math.floor(code / 100) === 5 ? 'error' : '';
        return String(STATUS_CODES[code]).replace(/error$/i, '') + suffix;
    }
}
export default HTTPError;
