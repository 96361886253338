import typography from '../typography';

const MuiFormLabel = {
    root: {
        ...typography.caption,
        fontSize: '16px',
    },
};

export default MuiFormLabel;
