import { REDUX } from 'constants/index';
import { getReducer } from '../lib/reducerCreator';
import { User } from 'types/users.types';
import { AnyAction, combineReducers } from 'redux';
import { ActiveUserProducer } from 'types/types.type';

const reducers = getReducer(REDUX.USERS, [] as User[]);

const initialUserState = {} as User;
const initialActiveProducer = {} as ActiveUserProducer;

const activeUser = (state = initialUserState, action: AnyAction): User => {
    switch (action.type) {
        case `${REDUX.ACTIVE_USER}/SUCCESS`: {
            return action?.payload?.activeUser || state;
        }

        case `${REDUX.ACTIVE_USER}/LOADING`:
        case `${REDUX.ACTIVE_USER}/ERROR`: {
            return initialUserState;
        }
        default:
            return state;
    }
};
const activeUserProducer = (state = initialActiveProducer, action: AnyAction): ActiveUserProducer => {
    switch (action.type) {
        case `${REDUX.ACTIVE_USER}/SUCCESS`: {
            return action?.payload?.activeUserProducer || state;
        }

        case `${REDUX.ACTIVE_USER}/LOADING`:
        case `${REDUX.ACTIVE_USER}/ERROR`: {
            return initialActiveProducer;
        }
        default:
            return state;
    }
};

const activeUserIsLoading = (state = true, action: AnyAction): boolean => {
    switch (action.type) {
        case `${REDUX.ACTIVE_USER}/SUCCESS`:
        case `${REDUX.ACTIVE_USER}/ERROR`: {
            return false;
        }

        case `${REDUX.ACTIVE_USER}/LOADING`: {
            return true;
        }
        default:
            return state;
    }
};

export default combineReducers({ activeUser, activeUserProducer, activeUserIsLoading, ...reducers });
