import { createSelector } from 'reselect';

import ApplicationState from '../store/ApplicationState';
import UWReviewsState from '../store/UwReviewsState';

const uwReviewsState = (state: ApplicationState) => state.uwReviews;
const data = (state: UWReviewsState) => state.data;
const loading = (state: UWReviewsState) => state.isLoading;
const error = (state: UWReviewsState) => state.error;

export const getUwReviewsData = createSelector(uwReviewsState, data);

export const getUwReviewsLoading = createSelector(uwReviewsState, loading);

export const getUwReviewsError = createSelector(uwReviewsState, error);
