import React from 'react';
import { Grid, withStyles } from '@material-ui/core';
import styles from './styles';
import LumenLoader from 'components/Loader';

interface LoadingProps {
    classes: any;
}

const Loading: React.FunctionComponent<LoadingProps> = ({ classes }) => (
    <Grid className={classes.root} container alignContent="center" alignItems="center" justifyContent="center">
        <Grid item className={classes.content}>
            <LumenLoader />
        </Grid>
    </Grid>
);

export default withStyles(styles)(Loading);
