import { combineReducers, AnyAction } from 'redux';
import { REDUX } from 'constants/index';
import { SubmissionDetails, SubmissionInspectionData, SubmissionUnderwritingStatus } from 'types/submission.type';
import { Organization } from 'types/organization.type';
import { Document2Post } from 'types/document.type';

const initialState = {
    id: '',
    effectiveDate: '',
    namedInsured: '',
    statusTypeId: '',
    statusType: {
        id: '',
        displayValue: '',
        code: '',
    },
    address1: '',
    address2: '',
    city: '',
    state: '',
    zip: '',
    narrative: '',
    producer: {} as Organization,
    boundDocuments: [],
    lossDocuments: {} as Map<string, Document2Post[]>,
    documents: [],
    locations: [],
    transactionEntries: [],
    losses: [],
    isEndorsement: false,
    endorsementTypeId: '',
    endorsingSubmissionId: '',
    isInForce: false,
    isExpiring: false,
    isPolicy: false,
    isRenewal: false,
    effectivePolicyNumber: '',
    hasAgentMessages: false,
    hasLumenMessages: false,
    uwStatus: {} as SubmissionUnderwritingStatus,
    inspectionData: {} as SubmissionInspectionData,
    underwritingNotes: '',
};

function data(state: SubmissionDetails = initialState, action: AnyAction): SubmissionDetails {
    switch (action.type) {
        // TODO: use this details object for all "selected submissions" instead of "selected" in submissions store
        case `${REDUX.FETCH_ONE_SUBMISSION}/SUCCESS`: {
            return action.payload;
        }

        case `${REDUX.FETCH_SUBMISSIONS}/LOADING`:
        case `${REDUX.CLEAR_SELECTED_SUBMISSION}`:
        case `${REDUX.FETCH_ONE_SUBMISSION}/ERROR`: {
            return initialState;
        }
        case `${REDUX.SUBMISSION_SAVE_UNDERWRITING_STATUS}`:
            return { ...state, uwStatus: action.payload };

        case REDUX.UW_REVIEW_SAVE_NOTES: {
            return {
                ...state,
                underwritingNotes: action.payload,
            };
        }

        default:
            return state;
    }
}

function error(state: string = '', action: AnyAction): string {
    switch (action.type) {
        case `${REDUX.FETCH_ONE_SUBMISSION}/ERROR`: {
            return action.payload;
        }
        case `${REDUX.FETCH_ONE_SUBMISSION}/LOADING`: {
            return '';
        }
        default:
            return state;
    }
}

function isLoading(state: boolean = false, action: AnyAction): boolean {
    switch (action.type) {
        case `${REDUX.FETCH_ONE_SUBMISSION}/LOADING`: {
            return true;
        }
        case `${REDUX.FETCH_ONE_SUBMISSION}/SUCCESS`:
        case `${REDUX.FETCH_ONE_SUBMISSION}/ERROR`: {
            return false;
        }
        default:
            return state;
    }
}

function hasResolved(state: boolean = false, action: AnyAction): boolean {
    switch (action.type) {
        case `${REDUX.FETCH_SUBMISSIONS}/LOADING`:
            return false;
        case `${REDUX.FETCH_ONE_SUBMISSION}/SUCCESS`:
        case `${REDUX.FETCH_ONE_SUBMISSION}/ERROR`: {
            return true;
        }
        default:
            return state;
    }
}

export default combineReducers({
    data,
    error,
    isLoading,
    hasResolved,
});
