import * as React from 'react';
import { Grid } from '@material-ui/core';

import LumenLoader2 from '../LumenLoader/lumenloader2/react';

interface Props {
    fillHeight?: string;
    size?: number;
    inTable?: boolean;
}

const defaultProps: Props = {
    fillHeight: '80vh',
    size: 75,
    inTable: false,
};

const LumenLoader: React.FunctionComponent<Props> = (props) => {
    const { fillHeight, inTable } = props;
    const padding = inTable ? 0 : '10% 25%';
    return (
        <Grid container alignItems="center" justifyContent="center" style={{ height: fillHeight, padding: padding }}>
            {/* TODO This animation needs to be fully "brought in" from haiku*/}
            {/*
            // @ts-ignore */}
            <LumenLoader2 loop={true} contextMenu={'disabled'} />
        </Grid>
    );
};

LumenLoader.defaultProps = defaultProps;
export default LumenLoader;
