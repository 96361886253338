import { Dispatch } from 'redux';
import { REDUX } from '../../../constants';
import actionCreator from '../lib/actionCreator';
import { fetchReportingLocations } from 'services/reporting';

const reportingLocationsFetchAction = actionCreator(REDUX.REPORTING_LOCATIONS);

export function reportingFetchLocations() {
    return async (dispatch: Dispatch) => {
        dispatch(reportingLocationsFetchAction.loading());
        fetchReportingLocations()
            .then((response) => {
                dispatch(reportingLocationsFetchAction.success(response));
            })
            .catch((reason) => {
                dispatch(reportingLocationsFetchAction.error(reason));
            });
    };
}
