import { AnyAction, combineReducers } from 'redux';
import { REDUX, REDUX_PERSIST, GRID_NAMES } from 'constants/index';
import SubmissionSOVReport from 'application/Models/SubmissionSOVReport';
import { getReducer } from '../lib/reducerCreator';
import { getGridReducer } from '../lib/gridReducerCreator';
import Location from '../../Models/Location';

const grid = getGridReducer(GRID_NAMES.SOV, REDUX_PERSIST.SOV);
const reducers = getReducer(REDUX.SOV, {} as SubmissionSOVReport);

const initialState = {} as SubmissionSOVReport;

reducers.data = (state = initialState, action: AnyAction) => {
    switch (action.type) {
        case `${REDUX.SOV}/SUCCESS`: {
            // we want to make sure they are sorted correctly
            (action.payload as SubmissionSOVReport).locations.sort(
                (f: Location, s: Location) => (f.submissionLocationNumber || 0) - (s.submissionLocationNumber || 0)
            );
            return action.payload || state;
        }
        case `${REDUX.SOV}/LOADING`:
        case `${REDUX.CLEAR_SELECTED_SUBMISSION}`:
        case `${REDUX.SOV}/ERROR`: {
            return initialState;
        }
        case `${REDUX.SOV_LOCATION_UW_NOTES}/SUCCESS`: {
            const newState = { ...state } as SubmissionSOVReport;
            newState.locations.forEach((l) => {
                if (l.id === action.payload.locationId) {
                    l.underwritingNote = action.payload.underwritingNote;
                }
            });
            return newState;
        }
        default:
            return state;
    }
};

export default combineReducers({
    ...reducers,
    grid,
});
