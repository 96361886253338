import { combineReducers, AnyAction } from 'redux';
import { REDUX } from 'constants/index';
import { UnderwriterReview, UwSubjectivity } from '../../../types/underwriting.type';
import { UWReviewData } from '../store/UWReviewState';

const initialState: UWReviewData = {
    propertyTableValues: [] as any[],
    underwriterReview: {} as UnderwriterReview,
    subjectivities: [],
};

const data = (state = initialState, action: AnyAction): UWReviewData => {
    switch (action.type) {
        case `${REDUX.UW_REVIEW}/SUCCESS`: {
            const { property, underwriterReview } = action.payload;
            return {
                propertyTableValues: property,
                underwriterReview,
                subjectivities: underwriterReview.subjectivities.map((subjectivity: UwSubjectivity) => ({
                    ...subjectivity,
                    submissionId: underwriterReview.submissionId, // adding the submissionId so we can correctly display source if this is from an aggregated
                })),
            };
        }
        case `${REDUX.UW_REVIEW}/LOADING`:
        case `${REDUX.CLEAR_SELECTED_SUBMISSION}`:
        case `${REDUX.UW_REVIEW}/ERROR`: {
            return initialState;
        }
        case REDUX.UW_REVIEW_SAVE_ALL_PROPERTIES: {
            return {
                ...state,
                propertyTableValues: action.payload,
            };
        }
        case REDUX.UW_REVIEW_SAVE_ONE_PROPERTY: {
            const { data: d, index } = action.payload;
            const propertyTableValues = [...state.propertyTableValues];
            propertyTableValues[index] = d;
            return {
                ...state,
                propertyTableValues,
            };
        }
        case REDUX.UW_REVIEW_SAVE_ALL_SUBJECTIVITIES: {
            return {
                ...state,
                subjectivities: action.payload,
            };
        }
        case REDUX.UW_REVIEW_SAVE_UW_REVIEW: {
            return {
                ...state,
                underwriterReview: action.payload,
            };
        }
        default:
            return state;
    }
};

function error(state: string = '', action: AnyAction): string {
    switch (action.type) {
        case `${REDUX.UW_REVIEW}/ERROR`: {
            return action.payload || 'Error Occurred';
        }
        case `${REDUX.UW_REVIEW}/LOADING`: {
            return '';
        }
        default:
            return state;
    }
}

function isLoading(state: boolean = false, action: AnyAction): boolean {
    switch (action.type) {
        case `${REDUX.UW_REVIEW}/LOADING`: {
            return true;
        }
        case `${REDUX.UW_REVIEW}/SUCCESS`:
        case `${REDUX.UW_REVIEW}/ERROR`: {
            return false;
        }
        default:
            return state;
    }
}

export default combineReducers({
    data,
    error,
    isLoading,
});
