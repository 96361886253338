import { StyleRules } from '@material-ui/core/styles';

const styles = (): StyleRules => ({
    root: {
        height: '100vh',
        background: '#fafafa',
    },
    content: {
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'center',
    },
});

export default styles;
