export const LOCATION_CLASSES = {
    APTS_GARDEN_STYLE: 'Apts - Garden Style',
    APTS_HIGH_RISE: 'Apts - High Rise',
    APTS_MULTI_USE: 'Apts - Multi Use',
    APTS_SR_IND_LIVING: 'Apts - Sr. Ind. Living',
    COMMERCIAL_OFFICE: 'Commercial Office',
    CONDO_OWNER_OCCUPIED: 'Condo - Owner Occ',
    CONDO_RENTAL: 'Condo - Rental',
    DUPLEX: 'Duplex',
    HOTEL_MOTEL: 'Hotel/Motel',
    OTHER: 'Other',
    RETAIL_STRIP: 'Retail Strip',
    SINGLE_FAMILY_DWELLING: 'Single Fam Dwelling',
    WAREHOUSE: 'Warehouse',
};

export const LOCATION_CONSTRUCTION_TYPES = {
    FRAME: 'Frame',
    JOISTED_MASONRY: 'Joisted Masonry',
    NON_COMBUSTIBLE: 'Non-Combustible',
    MASONRY_NON_COMBUSTIBLE: 'Masonry Non-Combustible',
    SEMI_FIRE_RESISTIVE: 'Semi Fire Resistive',
    FIRE_RESISTIVE: 'Fire Resistive',
};

export const LOCATION_WIRING_TYPES = {
    COPPER: 'Copper',
    PROTECTED_ALUMINUM_PIGTAIL: 'Protected Aluminum (Pigtail)',
    PROTECTED_ALUMINUM_CO_ALR: 'Protected Aluminum (CO/ALR)',
};

export const LOCATION_ROOF_TYPES = {
    GABLE_STEEP: 'Gable Steep',
    GABLE_AVERAGE: 'Gable Average',
    FLAT: 'Flat',
    MANSARD: 'Mansard',
};

export const LOCATION_ROOF_COVERING_TYPES = {
    ASPHALT_COMPOSITION: 'Asphalt/Composition',
    CONCRETE_TILE: 'Concrete Tile',
    CLAY_TILE: 'Clay Tile',
    METAL: 'Metal',
    EPDM_RUBBER: 'EPDM Rubber',
    MODIFIED_BITUMEN: 'Modified Bitumen',
    SPRAY_FOAM: 'Spray Foam',
    TAR_AND_GRAVEL: 'Tar & Gravel',
    TPO: 'TPO',
};

export const LOCATION_OWNERSHIP_TYPES = {
    OWNED: 'Owned',
    MANAGED: 'Managed',
    OWNED_MANAGED: 'Owned & Managed',
};

export const LOCATION_FLOOD_ZONE_TYPES = {
    A: 'A',
    AE: 'AE',
    AH: 'AH',
    B: 'B',
    C: 'C',
    D: 'D',
    UNKNOWN: 'Unknown',
    V: 'V',
    VE: 'VE',
    X: 'X',
    X500: 'X500',
    X_SHADED: 'X Shaded',
    X_UNSHADED: 'X Unshaded',
};

export const LOCATION_PERIL_TYPES = {
    FIRE: 'Fire',
    WIND_HAIL: 'Wind/Hail',
    FLOOD: 'Flood',
    WATER_DAMAGE: 'Water Damage',
    NAMED_STORM_WIND_HAIL: 'NS - WHH',
    NAMED_STORM_FLOOD: 'NS - Flood',
    OTHER: 'Other',
};

export const LOCATION_WHH_OPTIONS = [
    '$5,000',
    '$10,000',
    '$15,000',
    '$25,000',
    '$50,000',
    '$75,000',
    '$100,000',
    '$125,000',
    '$150,000',
    '$200,000',
    '$250,000',
    '$350,000',
    '$500,000',
    '$750,000',
    '$5,000 Per Building',
    '$10,000 Per Building',
    '$15,000 Per Building',
    '$25,000 Per Building',
    '$50,000 Per Building',
    '$100,000 Per Building',
    '$250,000 Per Building',
    '1% of TIV',
    '2% of TIV',
    '3% of TIV',
    '5% of TIV',
    '1% of TIV Per Building',
    '2% of TIV Per Building',
    '3% of TIV Per Building',
    '5% of TIV Per Building',
    '1% of TIV Per Building Subject to $100,000 Minimum',
    '2% of TIV Subject to $100,000 Minimum',
];

export const LOCATION_AOP_OPTIONS = [
    '$5,000',
    '$10,000',
    '$15,000',
    '$25,000',
    '$50,000',
    '$75,000',
    '$100,000',
    '$125,000',
    '$150,000',
    '$200,000',
    '$250,000',
];

export const LOCATION_NSD_OPTIONS = LOCATION_WHH_OPTIONS;
// [
//     '1% of TIV',
//     '2% of TIV',
//     '3% of TIV',
//     '5% of TIV',
//     '1% of TIV Per Building',
//     '2% of TIV Per Building',
//     '3% of TIV Per Building',
//     '5% of TIV Per Building',
//     '$5,000 ',
//     '$10,000',
//     '$15,000',
//     '$25,000',
//     '$50,000',
//     '$75,000',
//     '$100,000',
//     '$125,000',
//     '$150,000',
//     '$200,000',
//     '$250,000',
//     '$350,000',
//     '$500,000',
//     '$750,000',
// ];

export const LOCATION_FLOOD_EQ_OPTIONS = ['$10,000', '$25,000', '$50,000', '$100,000', '$250,000'];
export const LOCATION_FLOOD_EQ_LIMIT_OPTIONS = ['$0', '$1,000,000', '$2,500,000', '$5,000,000', '$10,000,000'];
