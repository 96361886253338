import { getHeaders, SubmissionURL } from './config';
import { checkStatus, getData, sortByCreateDate } from './normalizer';
import moment from 'moment';
import { CreateQuotePayload, Quote } from 'types/quote.type';
import { setTypes } from 'helpers/typeHelper';
import { LogCreateOptions } from 'types/logs.types';

const formatDate = (date?: string) => (date ? moment(date).format('MM/DD/YYYY') : undefined);
function formatData(quotes: Quote[]) {
    return quotes.map((quote) => ({
        ...quote,
        requestedEffectiveDate: formatDate(quote.requestedEffectiveDate),
        submissionEffectiveDate: formatDate(quote.submissionEffectiveDate),
        submissionExpirationDate: formatDate(quote.submissionExpirationDate),
        createDate: formatDate(quote.createDate),
        expirationDate: formatDate(quote.expirationDate),
        sentDate: formatDate(quote.sentDate),
        statusDate: formatDate(quote.statusDate),
    }));
}

export async function fetchQuotes(submissionId: string) {
    const url = `${SubmissionURL}/submission/${submissionId}/quote-summary`;
    const options = {
        method: 'GET',
        headers: getHeaders(),
    };
    return fetch(url, options)
        .then(checkStatus)
        .then(getData)
        .then((data: any) => sortByCreateDate(data.quotes))
        .then(formatData)
        .then(setTypes);
}

export async function fetchQuotesForBindReview(submissionId: string) {
    const url = `${SubmissionURL}/submission/${submissionId}/quotes-for-bind-review`;
    const options = {
        method: 'GET',
        headers: getHeaders(),
    };
    return fetch(url, options)
        .then(checkStatus)
        .then(getData)
        .then((data: any) => sortByCreateDate(data.quotes))
        .then(formatData)
        .then(setTypes);
}

export async function fetchQuotesForReview(submissionId: string) {
    const url = `${SubmissionURL}/submission/${submissionId}/quotes-for-review`;
    const options = {
        method: 'GET',
        headers: getHeaders(),
    };
    return fetch(url, options).then(checkStatus).then(getData).then(formatData).then(setTypes);
}

export async function createQuote(submissionId: string, payload: CreateQuotePayload) {
    const url = `${SubmissionURL}/submission/${submissionId}/quote`;
    const options = {
        method: 'POST',
        headers: getHeaders(),
        body: JSON.stringify(payload),
    };
    return fetch(url, options).then(checkStatus).then(getData);
}

export async function sendQuote(submissionId: string, quoteId: string) {
    const url = `${SubmissionURL}/submission/${submissionId}/quote/${quoteId}/send-to-agent`;
    const options = {
        method: 'POST',
        headers: getHeaders(),
    };
    return fetch(url, options).then(checkStatus);
}

export async function withdrawQuote(submissionId: string, quoteId: string, opts?: LogCreateOptions) {
    const url = `${SubmissionURL}/submission/${submissionId}/quote/${quoteId}/withdraw`;
    const options = {
        method: 'POST',
        headers: getHeaders(),
        body: JSON.stringify(opts),
    };
    return fetch(url, options).then(checkStatus);
}

export async function returnSubmissionToUnderwriting(submissionId: string, opts?: LogCreateOptions) {
    const url = `${SubmissionURL}/submission/${submissionId}/return-to-underwriting`;
    const options = {
        method: 'POST',
        headers: getHeaders(),
        body: JSON.stringify(opts),
    };
    return fetch(url, options).then(checkStatus);
}

export async function submitBindRequest(submissionId: string, quoteId: string, payload: any) {
    const url = `${SubmissionURL}/submission/${submissionId}/quote/${quoteId}/bind-request-review`;
    const options = {
        method: 'POST',
        headers: getHeaders(),
        body: JSON.stringify(payload),
    };
    return fetch(url, options).then(checkStatus);
}

export async function approveBindRequest(submissionId: string, quoteId: string, effectiveDate: string) {
    const url = `${SubmissionURL}/submission/${submissionId}/quote/${quoteId}/approve-bind`;
    const options = {
        method: 'POST',
        headers: getHeaders(),
        body: JSON.stringify({ effectiveDate: effectiveDate }),
    };
    return fetch(url, options).then(checkStatus);
}

export async function requestRequote(submissionId: string, quoteId: string, opts?: LogCreateOptions) {
    const url = `${SubmissionURL}/submission/${submissionId}/quote/${quoteId}/requote`;
    const options = {
        method: 'POST',
        headers: getHeaders(),
        body: JSON.stringify(opts),
    };
    return fetch(url, options).then(checkStatus);
}

export async function submitQuoteTaxes(underwriterReviewId: string, payload: any) {
    const url = `${SubmissionURL}/underwriter-reviews/${underwriterReviewId}`;
    const options = {
        method: 'PATCH',
        headers: getHeaders(),
        body: JSON.stringify(payload),
    };
    return fetch(url, options).then(checkStatus);
}

export async function uwRequote(submissionId: string) {
    const url = `${SubmissionURL}/submission/${submissionId}/uw-requote`;
    const options = {
        method: 'POST',
        headers: getHeaders(),
    };
    return fetch(url, options).then(checkStatus);
}

export async function fetchTaxesAndFeesForQuote(stateCode: string) {
    const url = `${SubmissionURL}/ilsa/taxrate?stateCode=${stateCode}`;
    const options = {
        method: 'GET',
        headers: getHeaders(),
    };
    return fetch(url, options).then(checkStatus).then(getData);
}
