import { Organization } from 'types/organization.type';
import { BaseActiveProducer } from 'helpers/ActiveUserProducer/BaseActiveProducer';
import { Link } from '@material-ui/core';
import * as React from 'react';

export class SupportActiveProducer extends BaseActiveProducer {
    getSubHeader(assignedProducer: Organization, doSelectProducer: () => void): React.ReactElement | string {
        if (!assignedProducer) {
            return (
                <>
                    <Link
                        href="#"
                        onClick={() => {
                            doSelectProducer();
                        }}
                    >
                        Select Producer Context
                    </Link>
                </>
            );
        }
        return super.getSubHeader(assignedProducer, doSelectProducer);
    }

    validateAssignedProducer(assignedProducer: Organization): Organization | undefined {
        if (assignedProducer) {
            const foundProducer = this.availableProducers.find((p) => p.id === assignedProducer.id);
            if (foundProducer) {
                return foundProducer;
            }
        }
        return undefined;
    }
}
