import { COLORS } from 'constants/index';
const MuiRadio = {
    colorPrimary: {
        color: COLORS.INFO,
        '&$checked': {
            color: COLORS.INFO,
        },
    },
};

export default MuiRadio;
