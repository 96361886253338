import { COLORS } from 'constants/index';

const MuiSwitch = {
    bar: {
        borderRadius: '13px',
        width: '42px',
        height: '26px',
        marginTop: '-13px',
        marginLeft: '-21px',
        border: 'solid 1px',
        borderColor: COLORS.GRAY_LIGHT,
        backgroundColor: COLORS.GRAY_LIGHT,
        opacity: '1',
    },
    icon: {
        backgroundColor: COLORS.GRAY_MED,
    },
    iconChecked: {
        backgroundColor: COLORS.PRIMARY,
    },
    colorSecondary: {
        '&$checked': {
            color: COLORS.WHITE,
            '& + $bar': {
                backgroundColor: COLORS.WHITE,
            },
        },
    },
};

export default MuiSwitch;
