import { getHeaders, SubmissionURL } from './config';
import { checkStatus, getData } from './normalizer';

export async function fetchSov(submissionId: string) {
    const url = `${SubmissionURL}/submission/${submissionId}/sov`;
    const options = {
        method: 'GET',
        headers: getHeaders(),
    };
    return fetch(url, options).then(checkStatus).then(getData);
}

export async function parseSov(sovFile: File) {
    const url = `${SubmissionURL}/utils/sov-parse`;
    const options = {
        method: 'POST',
        headers: getHeaders(),
        body: sovFile,
    };
    return fetch(url, options).then(checkStatus).then(getData);
}

export async function parseAcord(acordFile: File) {
    const url = `${SubmissionURL}/utils/acord-parse`;
    const options = {
        method: 'POST',
        headers: getHeaders(),
        body: acordFile,
    };
    return fetch(url, options).then(checkStatus).then(getData);
}
